import { type Config } from 'tailwindcss'
import colors from 'tailwindcss/colors.js'
import defaultTheme from 'tailwindcss/defaultTheme.js'

export const extendedTheme = {
	transparent: 'transparent',
	current: 'currentColor',
	colors: {
		// tremor light mode
		tremor: {
			brand: {
				faint: colors.blue[50],
				muted: colors.blue[200],
				subtle: colors.blue[400],
				DEFAULT: colors.blue[500],
				emphasis: colors.blue[700],
				inverted: colors.white,
			},
			background: {
				muted: colors.neutral[50],
				subtle: colors.neutral[100],
				DEFAULT: colors.white,
				emphasis: colors.neutral[700],
			},
			border: {
				DEFAULT: colors.neutral[200],
			},
			ring: {
				DEFAULT: colors.neutral[200],
			},
			content: {
				subtle: colors.neutral[400],
				DEFAULT: colors.neutral[500],
				emphasis: colors.neutral[700],
				strong: colors.neutral[900],
				inverted: colors.white,
			},
		},
		// tremor dark mode
		'dark-tremor': {
			brand: {
				faint: '#0B1229',
				muted: colors.blue[950],
				subtle: colors.blue[800],
				DEFAULT: colors.blue[500],
				emphasis: colors.blue[400],
				inverted: colors.blue[950],
			},
			background: {
				muted: '#131A2B',
				subtle: colors.neutral[800],
				DEFAULT: colors.neutral[900],
				emphasis: colors.neutral[300],
			},
			border: {
				DEFAULT: colors.neutral[800],
			},
			ring: {
				DEFAULT: colors.neutral[800],
			},
			content: {
				subtle: colors.neutral[600],
				DEFAULT: colors.neutral[500],
				emphasis: colors.neutral[200],
				strong: colors.neutral[50],
				inverted: colors.neutral[950],
			},
			// sidebar
			sidebar: {
				DEFAULT: 'hsl(var(--sidebar-background))',
				foreground: 'hsl(var(--sidebar-foreground))',
				primary: 'hsl(var(--sidebar-primary))',
				'primary-foreground': 'hsl(var(--sidebar-primary-foreground))',
				accent: 'hsl(var(--sidebar-accent))',
				'accent-foreground': 'hsl(var(--sidebar-accent-foreground))',
				border: 'hsl(var(--sidebar-border))',
				ring: 'hsl(var(--sidebar-ring))',
			},
		},
		boxShadow: {
			// light
			'tremor-input': '0 1px 2px 0 rgb(0 0 0 / 0.05)',
			'tremor-card':
				'0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)',
			'tremor-dropdown':
				'0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)',
			// dark
			'dark-tremor-input': '0 1px 2px 0 rgb(0 0 0 / 0.05)',
			'dark-tremor-card':
				'0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)',
			'dark-tremor-dropdown':
				'0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)',
		},
		// sidebar
		sidebar: {
			DEFAULT: 'hsl(var(--sidebar-background))',
			foreground: 'hsl(var(--sidebar-foreground))',
			primary: 'hsl(var(--sidebar-primary))',
			'primary-foreground': 'hsl(var(--sidebar-primary-foreground))',
			accent: 'hsl(var(--sidebar-accent))',
			'accent-foreground': 'hsl(var(--sidebar-accent-foreground))',
			border: 'hsl(var(--sidebar-border))',
			ring: 'hsl(var(--sidebar-ring))',
		},

		// normal
		border: 'hsl(var(--border))',
		input: {
			DEFAULT: 'hsl(var(--input))',
			invalid: 'hsl(var(--input-invalid))',
		},
		ring: {
			DEFAULT: 'hsl(var(--ring))',
			invalid: 'hsl(var(--foreground-destructive))',
		},
		background: 'hsl(var(--background))',
		foreground: {
			DEFAULT: 'hsl(var(--foreground))',
			destructive: 'hsl(var(--foreground-destructive))',
		},
		primary: {
			DEFAULT: 'hsl(var(--primary))',
			foreground: 'hsl(var(--primary-foreground))',
		},
		secondary: {
			DEFAULT: 'hsl(var(--secondary))',
			foreground: 'hsl(var(--secondary-foreground))',
		},
		destructive: {
			DEFAULT: 'hsl(var(--destructive))',
			foreground: 'hsl(var(--destructive-foreground))',
		},
		muted: {
			DEFAULT: 'hsl(var(--muted))',
			foreground: 'hsl(var(--muted-foreground))',
		},
		accent: {
			DEFAULT: 'hsl(var(--accent))',
			foreground: 'hsl(var(--accent-foreground))',
		},
		popover: {
			DEFAULT: 'hsl(var(--popover))',
			foreground: 'hsl(var(--popover-foreground))',
		},
		card: {
			DEFAULT: 'hsl(var(--card))',
			foreground: 'hsl(var(--card-foreground))',
		},
	},
	borderColor: {
		DEFAULT: 'hsl(var(--border))',
	},
	borderRadius: {
		lg: 'var(--radius)',
		md: 'calc(var(--radius) - 2px)',
		sm: 'calc(var(--radius) - 4px)',
		'tremor-small': '0.375rem',
		'tremor-default': '0.5rem',
		'tremor-full': '9999px',
	},
	fontFamily: {
		sans: ['Geist Sans', ...defaultTheme.fontFamily.sans],
	},
	fontSize: {
		// 1rem = 16px
		/** 80px size / 84px high / bold */
		mega: ['5rem', { lineHeight: '5.25rem', fontWeight: '700' }],
		/** 56px size / 62px high / bold */
		h1: ['3.5rem', { lineHeight: '3.875rem', fontWeight: '700' }],
		/** 40px size / 48px high / bold */
		h2: ['2.5rem', { lineHeight: '3rem', fontWeight: '700' }],
		/** 32px size / 36px high / bold */
		h3: ['2rem', { lineHeight: '2.25rem', fontWeight: '700' }],
		/** 28px size / 36px high / bold */
		h4: ['1.75rem', { lineHeight: '2.25rem', fontWeight: '700' }],
		/** 24px size / 32px high / bold */
		h5: ['1.5rem', { lineHeight: '2rem', fontWeight: '700' }],
		/** 16px size / 20px high / bold */
		h6: ['1rem', { lineHeight: '1.25rem', fontWeight: '700' }],

		/** 32px size / 36px high / normal */
		'body-2xl': ['2rem', { lineHeight: '2.25rem' }],
		/** 28px size / 36px high / normal */
		'body-xl': ['1.75rem', { lineHeight: '2.25rem' }],
		/** 24px size / 32px high / normal */
		'body-lg': ['1.5rem', { lineHeight: '2rem' }],
		/** 20px size / 28px high / normal */
		'body-md': ['1.25rem', { lineHeight: '1.75rem' }],
		/** 16px size / 20px high / normal */
		'body-sm': ['1rem', { lineHeight: '1.25rem' }],
		/** 14px size / 18px high / normal */
		'body-xs': ['0.875rem', { lineHeight: '1.125rem' }],
		/** 12px size / 16px high / normal */
		'body-2xs': ['0.75rem', { lineHeight: '1rem' }],

		/** 18px size / 24px high / semibold */
		caption: ['1.125rem', { lineHeight: '1.5rem', fontWeight: '600' }],
		/** 12px size / 16px high / bold */
		button: ['0.75rem', { lineHeight: '1rem', fontWeight: '700' }],

		'tremor-label': ['0.75rem', { lineHeight: '1rem' }],
		'tremor-default': ['0.875rem', { lineHeight: '1.25rem' }],
		'tremor-title': ['1.125rem', { lineHeight: '1.75rem' }],
		'tremor-metric': ['1.875rem', { lineHeight: '2.25rem' }],
	},
	keyframes: {
		'caret-blink': {
			'0%,70%,100%': { opacity: '1' },
			'20%,50%': { opacity: '0' },
		},
		hide: {
			from: { opacity: '1' },
			to: { opacity: '0' },
		},
		slideDownAndFade: {
			from: { opacity: '0', transform: 'translateY(-6px)' },
			to: { opacity: '1', transform: 'translateY(0)' },
		},
		slideLeftAndFade: {
			from: { opacity: '0', transform: 'translateX(6px)' },
			to: { opacity: '1', transform: 'translateX(0)' },
		},
		slideUpAndFade: {
			from: { opacity: '0', transform: 'translateY(6px)' },
			to: { opacity: '1', transform: 'translateY(0)' },
		},
		slideRightAndFade: {
			from: { opacity: '0', transform: 'translateX(-6px)' },
			to: { opacity: '1', transform: 'translateX(0)' },
		},
		accordionOpen: {
			from: { height: '0px' },
			to: { height: 'var(--radix-accordion-content-height)' },
		},
		accordionClose: {
			from: {
				height: 'var(--radix-accordion-content-height)',
			},
			to: { height: '0px' },
		},
		dialogOverlayShow: {
			from: { opacity: '0' },
			to: { opacity: '1' },
		},
		dialogContentShow: {
			from: {
				opacity: '0',
				transform: 'translate(-50%, -45%) scale(0.95)',
			},
			to: { opacity: '1', transform: 'translate(-50%, -50%) scale(1)' },
		},
		drawerSlideLeftAndFade: {
			from: { opacity: '0', transform: 'translateX(100%)' },
			to: { opacity: '1', transform: 'translateX(0)' },
		},
		drawerSlideRightAndFade: {
			from: { opacity: '1', transform: 'translateX(0)' },
			to: { opacity: '0', transform: 'translateX(100%)' },
		},
	},
	animation: {
		'caret-blink': 'caret-blink 1.25s ease-out infinite',
		hide: 'hide 150ms cubic-bezier(0.16, 1, 0.3, 1)',
		slideDownAndFade: 'slideDownAndFade 150ms cubic-bezier(0.16, 1, 0.3, 1)',
		slideLeftAndFade: 'slideLeftAndFade 150ms cubic-bezier(0.16, 1, 0.3, 1)',
		slideUpAndFade: 'slideUpAndFade 150ms cubic-bezier(0.16, 1, 0.3, 1)',
		slideRightAndFade: 'slideRightAndFade 150ms cubic-bezier(0.16, 1, 0.3, 1)',
		// Accordion
		accordionOpen: 'accordionOpen 150ms cubic-bezier(0.87, 0, 0.13, 1)',
		accordionClose: 'accordionClose 150ms cubic-bezier(0.87, 0, 0.13, 1)',
		// Dialog
		dialogOverlayShow: 'dialogOverlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1)',
		dialogContentShow: 'dialogContentShow 150ms cubic-bezier(0.16, 1, 0.3, 1)',
		// Drawer
		drawerSlideLeftAndFade:
			'drawerSlideLeftAndFade 150ms cubic-bezier(0.16, 1, 0.3, 1)',
		drawerSlideRightAndFade: 'drawerSlideRightAndFade 150ms ease-in',
	},
} satisfies Config['theme']
